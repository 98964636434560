import React, { useState } from "react"
import ReactModal from "react-modal"
import styled from "styled-components"

const customStyles = {
  overlay: {
    backgroundColor: "grey",
  },
  content: {
    borderRadius: ".8em",
    bottom: "auto",
    color: "rgba(0, 0, 0, 0.8)",
    height: "fit-content",
    left: "50%",
    marginRight: "-50%",
    marginTop: "20px",
    padding: "1em",
    paddingTop: "2.5em",
    right: "auto",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
  },
}

const CloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  color: black;
  border: none;
  :hover {
    cursor: pointer;
  }
`

const Modal = props => {
  const [isModalOpen, toggleModal] = useState(false)
  const { company, role, fullDescription, technicalSkills } = props.data
  if (typeof document !== `undefined`) {
    ReactModal.setAppElement(document.getElementById("root"))
  }
  return (
    <>
      <span
        onClick={() => {
          toggleModal(true)
        }}
      >
        {props.modalButtonName || "read more"}
      </span>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={() => {
          toggleModal(false)
        }}
        // onAfterOpen={() => (document.body.style.overflow = "hidden")}
        onAfterClose={() => (document.body.style.overflow = "unset")}
        style={customStyles}
        contentLabel="Experience Modal"
      >
        <CloseButton
          id="close"
          onClick={() => {
            toggleModal(false)
          }}
        >
          &times;
        </CloseButton>
        <h1>{company}</h1>
        <h3>{role}</h3>
        {fullDescription.map((description, idx) => (
          <p key={idx}>{description}</p>
        ))}
        {technicalSkills ? (
          <p>
            <strong>Technical Skills:</strong> {technicalSkills}
          </p>
        ) : null}
      </ReactModal>
    </>
  )
}

export default Modal
