import React from "react"
import Modal from "../components/modal"
import Teach from "../images/teach.js"
import Coding from "../images/coding.js"
import Freelance from "../images/freelance.js"

import "./experienceCard.css"

const ExperienceCard = inputProps => {
  const {
    company,
    role,
    description,
    imageURL,
    tags,
    position,
  } = inputProps.data
  return (
    <>
      <div
        className={
          position === "left" ? "experience-card" : "experience-card alt"
        }
      >
        <div className="meta">
          <div className="photo">
            {imageURL === "coding" ? (
              <Coding />
            ) : imageURL === "teach" ? (
              <Teach />
            ) : (
              <Freelance />
            )}
          </div>

          <ul className="details">
            {tags.map(tag => (
              <li className="tags" key={tag}>
                {tag}
              </li>
            ))}
          </ul>
        </div>
        <div className="description">
          <h1>{company}</h1>
          <h2>{role}</h2>
          <p>{description}</p>
          <p className="read-more">
            <Modal data={inputProps.data} />
          </p>
        </div>
      </div>
    </>
  )
}

export default ExperienceCard
