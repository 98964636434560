import React from "react"
import ExperienceCard from "../components/experienceCard"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { SectionHeader } from "../utils/commonComponents"
import { experiences } from "../data/portfolio"
// import TechStack from "../components/techstackmarquee"

const ExperiencePage = () => (
  <Layout>
    <SEO title="experience" />
    <SectionHeader header="PROFESSIONAL EXPERIENCE" />
    {experiences.map((experience, idx) => (
      <div
        data-sal={idx % 2 === 0 ? "slide-right" : "slide-left"}
        data-sal-delay="200"
        data-sal-duration="500"
        data-sal-easing="ease"
        key={experience.company}
      >
        <ExperienceCard data={experience} key={experience.company} />
      </div>
    ))}
    {/* <TechStack /> */}
  </Layout>
)

export default ExperiencePage
