import React from "react"

const Freelance = props => (
  <svg
    id="20c5e55c-796e-458b-93a1-d41b60cf5ace"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 990 758.22"
  >
    <defs>
      <linearGradient
        id="93a796c9-07fd-4853-99da-c116e42173a1"
        x1="369.6"
        y1="747.98"
        x2="495.41"
        y2="747.98"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="gray" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
        <stop offset="1" stopColor="gray" stopOpacity="0.1" />
      </linearGradient>
      <linearGradient
        id="70154093-e187-4646-b30c-626de43abe13"
        x1="406.65"
        y1="569.23"
        x2="580.77"
        y2="569.23"
      />
      <linearGradient
        id="55d67bdd-f841-4b0a-bab2-5935a2e0684e"
        x1="406.65"
        y1="566.88"
        x2="580.77"
        y2="566.88"
      />
      <linearGradient
        id="1d348026-d871-43ec-bb54-652c6952b12d"
        x1="450.31"
        y1="393.69"
        x2="539.32"
        y2="393.69"
      />
      <linearGradient
        id="87f6c372-7200-438e-959b-888ae7b4f3ef"
        x1="332.62"
        y1="585.2"
        x2="485.91"
        y2="585.2"
      />
      <linearGradient
        id="f5747eb7-796f-4e5b-a5e8-23014518cc4a"
        x1="330.25"
        y1="585.2"
        x2="483.54"
        y2="585.2"
      />
      <linearGradient
        id="025de17a-9263-427f-8bda-91433a15d0ed"
        x1="325.69"
        y1="523.49"
        x2="468.11"
        y2="523.49"
      />
      <linearGradient
        id="8ec8ff6b-02b2-4144-90ec-4c793413aaa2"
        x1="512.25"
        y1="535.81"
        x2="666.3"
        y2="535.81"
      />
      <linearGradient
        id="be7635bb-c1b1-4e5a-bb2b-447218f6f8ae"
        x1="514.63"
        y1="535.81"
        x2="668.68"
        y2="535.81"
      />
      <linearGradient
        id="e0baafd8-dbc9-494f-84f0-4cb07f684944"
        x1="334.24"
        y1="335.17"
        x2="375.57"
        y2="335.17"
      />
      <linearGradient
        id="dae221d4-0192-44c3-96cf-aa49a3952e96"
        x1="404.05"
        y1="350.24"
        x2="432.54"
        y2="350.24"
      />
      <linearGradient
        id="a8070bf4-ea14-4c8b-8231-190ced56d36c"
        x1="554.15"
        y1="502.96"
        x2="564.83"
        y2="502.96"
      />
      <linearGradient
        id="a026e723-06e3-45fb-aa0b-6213c5428683"
        x1="382.15"
        y1="523.48"
        x2="401.05"
        y2="523.48"
      />
      <linearGradient
        id="03160754-9e89-4150-a7c3-9eba33b65742"
        x1="396.31"
        y1="603.61"
        x2="450.31"
        y2="603.61"
      />
      <linearGradient
        id="83f700bc-f16a-4d54-983f-afb7a0104002"
        x1="466.47"
        y1="363.78"
        x2="534.98"
        y2="363.78"
      />
      <linearGradient
        id="88ceb9a1-2b99-4760-946d-8daf68c19369"
        x1="355.99"
        y1="258.44"
        x2="465.17"
        y2="258.44"
      />
      <linearGradient
        id="5970cf6c-9ebc-4539-b98e-9f7003913bbd"
        x1="436.13"
        y1="299.32"
        x2="566.69"
        y2="299.32"
      />
      <linearGradient
        id="e01ddaa7-2644-42b6-8d02-05398d19a985"
        x1="434.94"
        y1="298.14"
        x2="565.5"
        y2="298.14"
      />
      <linearGradient
        id="e869773a-6e18-4855-8846-778848ca5604"
        x1="488.88"
        y1="375.59"
        x2="562.07"
        y2="375.59"
      />
      <linearGradient
        id="e34e72f2-f69c-427f-9512-751f0822cf10"
        x1="488.88"
        y1="376.76"
        x2="562.07"
        y2="376.76"
      />
      <linearGradient
        id="ffb5f874-c212-487b-9e8d-f484eff3d531"
        x1="765.65"
        y1="677.74"
        x2="765.65"
        y2="269.69"
      />
      <linearGradient
        id="8483b893-8b57-4eec-a040-6ce664ec9c86"
        x1="579.6"
        y1="758.22"
        x2="579.6"
        y2="555.42"
      />
      <linearGradient
        id="2944a9b5-2bb1-4246-b5f3-3c3767fdf1af"
        x1="578.2"
        y1="568.04"
        x2="578.2"
        y2="387.91"
      />
    </defs>
    <title>co-workers</title>
    <path
      d="M923,738.89H852v-40h71Zm-68-3h65v-34H855Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M994,736.89H923v-40h71Zm-68-3h65v-34H926Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M1065,736.89H994v-40h71Zm-68-3h65v-34H997Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M1065,696.89H994v-40h71Zm-68-3h65v-34H997Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M994,696.89H923v-40h71Zm-68-3h65v-34H926Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M194,736.89H123v-40h71Zm-68-3h65v-34H126Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M265,736.89H194v-40h71Zm-68-3h65v-34H197Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M336,736.89H265v-40h71Zm-68-3h65v-34H268Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M407,738.89H336v-40h71Zm-68-3h65v-34H339Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M336,696.89H265v-40h71Zm-68-3h65v-34H268Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M265,696.89H194v-40h71Zm-68-3h65v-34H197Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M995.28,613.25s-13.14-44.63,2.46-77.48a70.49,70.49,0,0,0,5.7-44.22,118.55,118.55,0,0,0-6.26-20.76"
      transform="translate(-105 -70.89)"
      fill="none"
      stroke="#535461"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M1006.9,450c0,5.47-9.9,21.33-9.9,21.33s-9.9-15.86-9.9-21.33a9.9,9.9,0,0,1,19.81,0Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M1023.27,480.33c-3,4.59-19.91,12.52-19.91,12.52s.31-18.7,3.28-23.29a9.9,9.9,0,0,1,16.63,10.76Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M1022.55,532.11c-4.88,2.46-23.5.76-23.5.76s9.7-16,14.59-18.45a9.9,9.9,0,0,1,8.92,17.69Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M1013.19,571.16c-4.4,3.25-23,4.72-23,4.72s6.86-17.39,11.26-20.65a9.9,9.9,0,1,1,11.78,15.92Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M982.57,493.92c3.92,3.81,22.2,7.76,22.2,7.76s-4.48-18.16-8.4-22a9.9,9.9,0,1,0-13.8,14.21Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M972.2,539.72c4.88,2.46,23.5.76,23.5.76s-9.7-16-14.59-18.45a9.9,9.9,0,0,0-8.92,17.69Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M968.23,585.63c4.4,3.25,23,4.72,23,4.72S984.41,573,980,569.71a9.9,9.9,0,1,0-11.78,15.92Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M1006.9,450c0,5.47-9.9,21.33-9.9,21.33s-9.9-15.86-9.9-21.33a9.9,9.9,0,0,1,19.81,0Z"
      transform="translate(-105 -70.89)"
      opacity="0.25"
    />
    <path
      d="M1023.27,480.33c-3,4.59-19.91,12.52-19.91,12.52s.31-18.7,3.28-23.29a9.9,9.9,0,0,1,16.63,10.76Z"
      transform="translate(-105 -70.89)"
      opacity="0.25"
    />
    <path
      d="M1022.55,532.11c-4.88,2.46-23.5.76-23.5.76s9.7-16,14.59-18.45a9.9,9.9,0,0,1,8.92,17.69Z"
      transform="translate(-105 -70.89)"
      opacity="0.25"
    />
    <path
      d="M1013.19,571.16c-4.4,3.25-23,4.72-23,4.72s6.86-17.39,11.26-20.65a9.9,9.9,0,1,1,11.78,15.92Z"
      transform="translate(-105 -70.89)"
      opacity="0.25"
    />
    <path
      d="M982.57,493.92c3.92,3.81,22.2,7.76,22.2,7.76s-4.48-18.16-8.4-22a9.9,9.9,0,1,0-13.8,14.21Z"
      transform="translate(-105 -70.89)"
      opacity="0.25"
    />
    <path
      d="M972.2,539.72c4.88,2.46,23.5.76,23.5.76s-9.7-16-14.59-18.45a9.9,9.9,0,0,0-8.92,17.69Z"
      transform="translate(-105 -70.89)"
      opacity="0.25"
    />
    <path
      d="M968.23,585.63c4.4,3.25,23,4.72,23,4.72S984.41,573,980,569.71a9.9,9.9,0,1,0-11.78,15.92Z"
      transform="translate(-105 -70.89)"
      opacity="0.25"
    />
    <path
      d="M273.8,613.59S260.46,568.3,276.3,535a71.54,71.54,0,0,0,5.79-44.88A120.31,120.31,0,0,0,275.73,469"
      transform="translate(-105 -70.89)"
      fill="none"
      stroke="#535461"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M285.59,448c0,5.55-10.05,21.65-10.05,21.65S265.48,453.5,265.48,448a10.05,10.05,0,0,1,20.1,0Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M302.2,478.7c-3,4.66-20.2,12.71-20.2,12.71s.31-19,3.33-23.63A10.05,10.05,0,1,1,302.2,478.7Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M301.47,531.24c-5,2.5-23.85.77-23.85.77s9.85-16.22,14.8-18.72a10.05,10.05,0,0,1,9.05,17.95Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M292,570.87c-4.46,3.3-23.38,4.79-23.38,4.79s7-17.65,11.43-21a10.05,10.05,0,0,1,12,16.16Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M260.89,492.48c4,3.87,22.53,7.87,22.53,7.87s-4.54-18.42-8.52-22.29a10.05,10.05,0,1,0-14,14.42Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M250.37,539c5,2.5,23.85.77,23.85.77s-9.85-16.22-14.8-18.72A10.05,10.05,0,0,0,250.37,539Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M246.34,585.56c4.46,3.3,23.38,4.79,23.38,4.79s-7-17.65-11.43-21a10.05,10.05,0,1,0-12,16.16Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M285.59,448c0,5.55-10.05,21.65-10.05,21.65S265.48,453.5,265.48,448a10.05,10.05,0,0,1,20.1,0Z"
      transform="translate(-105 -70.89)"
      fill="#fff"
      opacity="0.5"
    />
    <path
      d="M302.2,478.7c-3,4.66-20.2,12.71-20.2,12.71s.31-19,3.33-23.63A10.05,10.05,0,1,1,302.2,478.7Z"
      transform="translate(-105 -70.89)"
      fill="#fff"
      opacity="0.5"
    />
    <path
      d="M301.47,531.24c-5,2.5-23.85.77-23.85.77s9.85-16.22,14.8-18.72a10.05,10.05,0,0,1,9.05,17.95Z"
      transform="translate(-105 -70.89)"
      fill="#fff"
      opacity="0.5"
    />
    <path
      d="M292,570.87c-4.46,3.3-23.38,4.79-23.38,4.79s7-17.65,11.43-21a10.05,10.05,0,0,1,12,16.16Z"
      transform="translate(-105 -70.89)"
      fill="#fff"
      opacity="0.5"
    />
    <path
      d="M260.89,492.48c4,3.87,22.53,7.87,22.53,7.87s-4.54-18.42-8.52-22.29a10.05,10.05,0,1,0-14,14.42Z"
      transform="translate(-105 -70.89)"
      fill="#fff"
      opacity="0.5"
    />
    <path
      d="M250.37,539c5,2.5,23.85.77,23.85.77s-9.85-16.22-14.8-18.72A10.05,10.05,0,0,0,250.37,539Z"
      transform="translate(-105 -70.89)"
      fill="#fff"
      opacity="0.5"
    />
    <path
      d="M246.34,585.56c4.46,3.3,23.38,4.79,23.38,4.79s-7-17.65-11.43-21a10.05,10.05,0,1,0-12,16.16Z"
      transform="translate(-105 -70.89)"
      fill="#fff"
      opacity="0.5"
    />
    <path
      d="M197.69,612.47S187.53,578,199.6,552.55a54.52,54.52,0,0,0,4.41-34.2,91.69,91.69,0,0,0-4.84-16.05"
      transform="translate(-105 -70.89)"
      fill="none"
      stroke="#535461"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M206.68,486.24c0,4.23-7.66,16.5-7.66,16.5s-7.66-12.27-7.66-16.5a7.66,7.66,0,0,1,15.32,0Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M219.34,509.67c-2.3,3.55-15.4,9.69-15.4,9.69s.24-14.46,2.54-18a7.66,7.66,0,1,1,12.86,8.33Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M218.79,549.71c-3.78,1.9-18.18.59-18.18.59s7.5-12.36,11.28-14.27a7.66,7.66,0,0,1,6.9,13.68Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M211.55,579.92c-3.4,2.52-17.82,3.65-17.82,3.65s5.31-13.45,8.71-16a7.66,7.66,0,0,1,9.11,12.32Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M187.86,520.18c3,2.95,17.17,6,17.17,6s-3.46-14-6.5-17a7.66,7.66,0,1,0-10.67,11Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M179.84,555.61c3.78,1.9,18.18.59,18.18.59s-7.5-12.36-11.28-14.27a7.66,7.66,0,0,0-6.9,13.68Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M176.77,591.11c3.4,2.52,17.82,3.65,17.82,3.65s-5.31-13.45-8.71-16a7.66,7.66,0,1,0-9.11,12.32Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M206.68,486.24c0,4.23-7.66,16.5-7.66,16.5s-7.66-12.27-7.66-16.5a7.66,7.66,0,0,1,15.32,0Z"
      transform="translate(-105 -70.89)"
      opacity="0.25"
    />
    <path
      d="M219.34,509.67c-2.3,3.55-15.4,9.69-15.4,9.69s.24-14.46,2.54-18a7.66,7.66,0,1,1,12.86,8.33Z"
      transform="translate(-105 -70.89)"
      opacity="0.25"
    />
    <path
      d="M218.79,549.71c-3.78,1.9-18.18.59-18.18.59s7.5-12.36,11.28-14.27a7.66,7.66,0,0,1,6.9,13.68Z"
      transform="translate(-105 -70.89)"
      opacity="0.25"
    />
    <path
      d="M211.55,579.92c-3.4,2.52-17.82,3.65-17.82,3.65s5.31-13.45,8.71-16a7.66,7.66,0,0,1,9.11,12.32Z"
      transform="translate(-105 -70.89)"
      opacity="0.25"
    />
    <path
      d="M187.86,520.18c3,2.95,17.17,6,17.17,6s-3.46-14-6.5-17a7.66,7.66,0,1,0-10.67,11Z"
      transform="translate(-105 -70.89)"
      opacity="0.25"
    />
    <path
      d="M179.84,555.61c3.78,1.9,18.18.59,18.18.59s-7.5-12.36-11.28-14.27a7.66,7.66,0,0,0-6.9,13.68Z"
      transform="translate(-105 -70.89)"
      opacity="0.25"
    />
    <path
      d="M176.77,591.11c3.4,2.52,17.82,3.65,17.82,3.65s-5.31-13.45-8.71-16a7.66,7.66,0,1,0-9.11,12.32Z"
      transform="translate(-105 -70.89)"
      opacity="0.25"
    />
    <g opacity="0.7">
      <rect y="80" width="990" height="505" fill="#4285f4" opacity="0.6" />
      <path
        d="M105,150.89v506h990v-506Zm44,44H578v187H149Zm0,231H578v187H149Zm902,187H622v-187h429Zm0-231H622v-187h429Z"
        transform="translate(-105 -70.89)"
        opacity="0.25"
      />
    </g>
    <g opacity="0.7">
      <path
        d="M468.11,677.77s-86.64-31.68-98.5,12.91l33.23,84.47,3.56,54h72.39V783.36s-10.68-45.76,16.62-73.91Z"
        transform="translate(-105 -70.89)"
        fill="url(#93a796c9-07fd-4853-99da-c116e42173a1)"
      />
      <g opacity="0.1">
        <path
          d="M482.62,426.39c-3.68-1.22-8.11-2.31-11.11.12-1.74,1.41-2.49,3.68-3.84,5.46-4.49,5.89-13.54,4.79-20.69,6.9-10.51,3.1-17,13.51-20.72,23.71-16.46,45,1.89,96.47-12.44,142.17-4.22,13.45-11.12,28.38-4.3,40.75,2.27,4.13,6,7.57,7.1,12.13,1.54,6.19-1.43,14.31,3.76,18.08,1.62,1.17,3.67,1.54,5.61,2,11.68,3.08,20.53,12.17,29.43,20.25s19.6,15.9,31.67,15.37a51.57,51.57,0,0,0,11.48-2.28l59.9-16.68c6-1.67,12.52-3.69,16-8.81,2.13-3.13,2.8-7,3.34-10.72a282.42,282.42,0,0,0,2.93-42.93c0-4.74-.22-9.59-2-14-2-5.09-6-9.16-8.91-13.81-5.62-9-7.13-19.81-8.52-30.28-2.21-16.67-4.42-33.67-1.36-50.21,1.31-7.1,3.57-14,5.07-21.05a99.86,99.86,0,0,0,1.14-34.93c-1.4-9.65-4.4-19.44-11-26.7a27.91,27.91,0,0,0-16.6-9c-8.1-1-12.13,3.66-19.35,5.4C508.53,440,492.89,429.78,482.62,426.39Z"
          transform="translate(-105 -70.89)"
          fill="url(#70154093-e187-4646-b30c-626de43abe13)"
        />
      </g>
      <path
        d="M482.62,424c-3.68-1.22-8.11-2.31-11.11.12-1.74,1.41-2.49,3.68-3.84,5.46-4.49,5.89-13.54,4.79-20.69,6.9-10.51,3.1-17,13.51-20.72,23.71-16.46,45,1.89,96.47-12.44,142.17-4.22,13.45-11.12,28.38-4.3,40.75,2.27,4.13,6,7.57,7.1,12.13,1.54,6.19-1.43,14.31,3.76,18.08,1.62,1.17,3.67,1.54,5.61,2,11.68,3.08,20.53,12.17,29.43,20.25S475,711.56,487.09,711a51.57,51.57,0,0,0,11.48-2.28l59.9-16.68c6-1.67,12.52-3.69,16-8.81,2.13-3.13,2.8-7,3.34-10.72a282.42,282.42,0,0,0,2.93-42.93c0-4.74-.22-9.59-2-14-2-5.09-6-9.16-8.91-13.81-5.62-9-7.13-19.81-8.52-30.28-2.21-16.67-4.42-33.67-1.36-50.21,1.31-7.1,3.57-14,5.07-21.05a99.86,99.86,0,0,0,1.14-34.93c-1.4-9.65-4.4-19.44-11-26.7a27.91,27.91,0,0,0-16.6-9c-8.1-1-12.13,3.66-19.35,5.4C508.53,437.6,492.89,427.44,482.62,424Z"
        transform="translate(-105 -70.89)"
        fill="url(#55d67bdd-f841-4b0a-bab2-5935a2e0684e)"
      />
      <path
        d="M465.74,335.19s1.19,44.58-13.05,43.41S493,452.51,493,452.51l46.28-24.64S512,384.47,531,357.48,465.74,335.19,465.74,335.19Z"
        transform="translate(-105 -70.89)"
        fill="url(#1d348026-d871-43ec-bb54-652c6952b12d)"
      />
      <g opacity="0.1">
        <path
          d="M386.22,714.14s-9.49,64.53-33.23,46.93-20.18-46.93-20.18-46.93S379.1,589.78,376.73,571s79-164.7,79-164.7l30.14,239.79S395.71,678.94,386.22,714.14Z"
          transform="translate(-105 -70.89)"
          fill="url(#87f6c372-7200-438e-959b-888ae7b4f3ef)"
        />
      </g>
      <path
        d="M383.85,714.14s-9.49,64.53-33.23,46.93-20.18-46.93-20.18-46.93S376.73,589.78,374.35,571s79-164.7,79-164.7l30.14,239.79S393.34,678.94,383.85,714.14Z"
        transform="translate(-105 -70.89)"
        fill="url(#f5747eb7-796f-4e5b-a5e8-23014518cc4a)"
      />
      <path
        d="M468.11,363.35S421.82,395,404,393.85l-13.83,3.76a75.91,75.91,0,0,0-54.78,58.47l-.22,1.12C328.07,494.75,325.69,598,325.69,598s1.19,27,13.05,34l76,51.62s-2.37-56.31,26.11-62.18-7.12-2.35-7.12-2.35l-52.22-30.5s1.19-45.76,15.43-61,64.09-95,64.09-95l-14.24-49.27Z"
        transform="translate(-105 -70.89)"
        fill="url(#025de17a-9263-427f-8bda-91433a15d0ed)"
      />
      <g opacity="0.1">
        <path
          d="M519.14,393.85s29.15,7.63,49.56,11.36a20.54,20.54,0,0,1,14.84,11.2c6.16,12.71,15.55,35.7,13.93,54.87l17.8,69.22,51,61L620,622.63,567.8,565.14l42.72,109.11L531,677.77s-23.74-176-17.8-205.31Z"
          transform="translate(-105 -70.89)"
          fill="url(#8ec8ff6b-02b2-4144-90ec-4c793413aaa2)"
        />
      </g>
      <path
        d="M521.51,393.85s29.15,7.63,49.56,11.36a20.54,20.54,0,0,1,14.84,11.2c6.16,12.71,15.55,35.7,13.93,54.87l17.8,69.22,51,61-46.28,21.12-52.22-57.49L612.9,674.25l-79.52,3.52s-23.74-176-17.8-205.31Z"
        transform="translate(-105 -70.89)"
        fill="url(#be7635bb-c1b1-4e5a-bb2b-447218f6f8ae)"
      />
      <polygon
        points="359.21 288.72 375.57 368.13 348.87 381.62 334.24 310.09 359.21 288.72"
        fill="url(#e0baafd8-dbc9-494f-84f0-4cb07f684944)"
      />
      <polygon
        points="404.05 374 416.51 322.96 432.54 352.88 424.23 377.52 404.05 374"
        fill="url(#dae221d4-0192-44c3-96cf-aa49a3952e96)"
      />
      <path
        d="M554.15,460.14s0,79.78,10.68,85.64"
        transform="translate(-105 -70.89)"
        opacity="0.1"
        fill="url(#a8070bf4-ea14-4c8b-8231-190ced56d36c)"
      />
      <path
        d="M401.05,480.08s-24.63,78.57-17.66,86.8"
        transform="translate(-105 -70.89)"
        opacity="0.1"
        fill="url(#a026e723-06e3-45fb-aa0b-6213c5428683)"
      />
      <path
        d="M396.31,588s47.48,30.69,54,31.18"
        transform="translate(-105 -70.89)"
        opacity="0.1"
        fill="url(#03160754-9e89-4150-a7c3-9eba33b65742)"
      />
      <g opacity="0.1">
        <path
          d="M466.92,339.88s.18,6.87-.46,15.14a54.62,54.62,0,0,0,50.3,33,55.57,55.57,0,0,0,8.75-.7c-.31-8.78,1.38-17.59,6.68-25.11C551.18,335.19,466.92,339.88,466.92,339.88Z"
          transform="translate(-105 -70.89)"
          fill="url(#83f700bc-f16a-4d54-983f-afb7a0104002)"
        />
      </g>
      <ellipse
        cx="410.58"
        cy="258.44"
        rx="54.59"
        ry="53.97"
        fill="url(#88ceb9a1-2b99-4760-946d-8daf68c19369)"
      />
      <g opacity="0.1">
        <path
          d="M550.22,306.64c6.61.88,15-1.3,16.31-7.78,1.61-8.18-9.46-13.5-11.17-21.67-.64-3.06.12-6.26-.25-9.37-.74-6.26-5.86-11.08-11.07-14.72a85.87,85.87,0,0,0-56.62-14.92c-2.48.23-5.09.63-7,2.2-2.18,1.77-3,4.65-4.51,7-5.12,8.18-16.62,9.14-24.87,14.24-9.65,6-14.43,17.67-14.86,28.91s2.81,22.27,6,33.07c2.73,9.18,5.59,18.61,11.58,26.13s15.93,12.77,25.34,10.51c-4.31-3.07-1.18-10.2,3.49-12.7s10.47-2.93,14.46-6.38,5.19-9.1,6.49-14.24c1.64-6.48,4-13.06,8.8-17.79C521.75,299.87,538.7,305.11,550.22,306.64Z"
          transform="translate(-105 -70.89)"
          fill="url(#5970cf6c-9ebc-4539-b98e-9f7003913bbd)"
        />
      </g>
      <path
        d="M549,305.47c6.61.88,15-1.3,16.31-7.78,1.61-8.18-9.46-13.5-11.17-21.67-.64-3.06.12-6.26-.25-9.37-.74-6.26-5.86-11.08-11.07-14.72A85.87,85.87,0,0,0,486.24,237c-2.48.23-5.09.63-7,2.2-2.18,1.77-3,4.65-4.51,7-5.12,8.18-16.62,9.14-24.87,14.24-9.65,6-14.43,17.67-14.86,28.91s2.81,22.27,6,33.07c2.73,9.18,5.59,18.61,11.58,26.13s15.93,12.77,25.34,10.51c-4.31-3.07-1.18-10.2,3.49-12.7s10.47-2.93,14.46-6.38,5.19-9.1,6.49-14.24c1.64-6.48,4-13.06,8.8-17.79C520.56,298.7,537.51,303.93,549,305.47Z"
        transform="translate(-105 -70.89)"
        fill="url(#e01ddaa7-2644-42b6-8d02-05398d19a985)"
      />
      <g opacity="0.1">
        <path
          d="M506.14,336.61c-.8,5.58-2.58,15.24-5.93,21.34a170.66,170.66,0,0,0-11.33,24.76h0s21.86,16.73,33,28.16a25.92,25.92,0,0,0,27.22,6.59c5.79-2.08,10.86-6.1,12.21-13.64,2.12-11.89-.4-20.86-2.78-26.17a19.45,19.45,0,0,1-.08-16c.82-1.8,1.78-3.21,2.85-3.56,0,0-21.36-4.69-22.55,1.17s-17.8,11.73-21.36,1.17l-1-23.28a5.12,5.12,0,0,0-5.14-4.88h0A5.12,5.12,0,0,0,506.14,336.61Z"
          transform="translate(-105 -70.89)"
          fill="url(#e869773a-6e18-4855-8846-778848ca5604)"
        />
      </g>
      <path
        d="M506.14,337.79c-.8,5.58-2.58,15.24-5.93,21.34a170.66,170.66,0,0,0-11.33,24.76h0s21.86,16.73,33,28.16a25.92,25.92,0,0,0,27.22,6.59c5.79-2.08,10.86-6.1,12.21-13.64,2.12-11.89-.4-20.86-2.78-26.17a19.45,19.45,0,0,1-.08-16c.82-1.8,1.78-3.21,2.85-3.56,0,0-21.36-4.69-22.55,1.17s-17.8,11.73-21.36,1.17l-1-23.28a5.12,5.12,0,0,0-5.14-4.88h0A5.12,5.12,0,0,0,506.14,337.79Z"
        transform="translate(-105 -70.89)"
        fill="url(#e34e72f2-f69c-427f-9512-751f0822cf10)"
      />
    </g>
    <path
      d="M469.27,677s-83.17-30.76-94.56,12.53l31.9,82L410,824h69.5V779.55s-10.25-44.43,16-71.78Z"
      transform="translate(-105 -70.89)"
      fill="#535461"
    />
    <path
      d="M483.2,432.89c-3.53-1.18-7.79-2.24-10.66.12-1.67,1.37-2.39,3.57-3.69,5.3-4.31,5.72-13,4.65-19.86,6.7-10.09,3-16.31,13.12-19.89,23-15.8,43.69,1.81,93.69-11.94,138.06-4,13.06-10.67,27.56-4.13,39.57,2.18,4,5.73,7.35,6.82,11.78,1.48,6-1.37,13.9,3.61,17.56,1.55,1.14,3.52,1.49,5.38,2,11.21,3,19.71,11.82,28.25,19.67s18.82,15.44,30.4,14.93a49,49,0,0,0,11-2.21l57.5-16.2c5.77-1.63,12-3.59,15.37-8.56,2-3,2.69-6.78,3.21-10.41a277.38,277.38,0,0,0,2.81-41.69c0-4.6-.21-9.31-1.9-13.59-2-4.94-5.76-8.89-8.56-13.42-5.39-8.72-6.85-19.24-8.18-29.41-2.12-16.19-4.24-32.7-1.31-48.76,1.26-6.89,3.43-13.59,4.87-20.44a98.08,98.08,0,0,0,1.1-33.92c-1.34-9.37-4.23-18.88-10.55-25.93a26.71,26.71,0,0,0-15.94-8.74c-7.78-1-11.65,3.56-18.58,5.25C508.08,446.06,493.06,436.19,483.2,432.89Z"
      transform="translate(-105 -70.89)"
      opacity="0.1"
    />
    <path
      d="M483.2,430.61c-3.53-1.18-7.79-2.24-10.66.12-1.67,1.37-2.39,3.57-3.69,5.3-4.31,5.72-13,4.65-19.86,6.7-10.09,3-16.31,13.12-19.89,23-15.8,43.69,1.81,93.69-11.94,138.06-4,13.06-10.67,27.56-4.13,39.57,2.18,4,5.73,7.35,6.82,11.78,1.48,6-1.37,13.9,3.61,17.56,1.55,1.14,3.52,1.49,5.38,2,11.21,3,19.71,11.82,28.25,19.67s18.82,15.44,30.4,14.93a49,49,0,0,0,11-2.21L556,690.9c5.77-1.63,12-3.59,15.37-8.56,2-3,2.69-6.78,3.21-10.41a277.38,277.38,0,0,0,2.81-41.69c0-4.6-.21-9.31-1.9-13.59-2-4.94-5.76-8.89-8.56-13.42-5.39-8.72-6.85-19.24-8.18-29.41-2.12-16.19-4.24-32.7-1.31-48.76,1.26-6.89,3.43-13.59,4.87-20.44a98.08,98.08,0,0,0,1.1-33.92c-1.34-9.37-4.23-18.88-10.55-25.93A26.71,26.71,0,0,0,536.95,436c-7.78-1-11.65,3.56-18.58,5.25C508.08,443.79,493.06,433.91,483.2,430.61Z"
      transform="translate(-105 -70.89)"
      fill="#e0e0e0"
    />
    <path
      d="M467,344.33s1.14,43.29-12.53,42.15,38.74,71.78,38.74,71.78l44.43-23.93s-26.2-42.15-8-68.36S467,344.33,467,344.33Z"
      transform="translate(-105 -70.89)"
      fill="#fda57d"
    />
    <path
      d="M390.66,712.33s-9.11,62.66-31.9,45.57-19.37-45.57-19.37-45.57,44.43-120.77,42.15-139,75.88-159.95,75.88-159.95l28.93,232.86S399.77,678.15,390.66,712.33Z"
      transform="translate(-105 -70.89)"
      opacity="0.1"
    />
    <path
      d="M388.38,712.33s-9.11,62.66-31.9,45.57-19.37-45.57-19.37-45.57,44.43-120.77,42.15-139,75.88-159.95,75.88-159.95l28.93,232.86S397.49,678.15,388.38,712.33Z"
      transform="translate(-105 -70.89)"
      fill="#4d8af0"
    />
    <path
      d="M469.27,371.67s-44.43,30.76-61.52,29.62l-13.28,3.65a73.38,73.38,0,0,0-52.59,56.78l-.21,1.09c-6.84,36.46-9.11,136.72-9.11,136.72s1.14,26.2,12.53,33L418,682.71s-2.28-54.69,25.07-60.38-6.84-2.28-6.84-2.28L386.1,590.42s1.14-44.43,14.81-59.24,61.52-92.28,61.52-92.28L448.76,391Z"
      transform="translate(-105 -70.89)"
      fill="#4d8af0"
    />
    <path
      d="M518.26,401.3s28,7.41,47.58,11a19.74,19.74,0,0,1,14.24,10.88c5.91,12.35,14.92,34.66,13.37,53.28l17.09,67.22,49,59.24L615.1,623.46,565,567.64l41,106L529.66,677s-22.79-170.9-17.09-199.38Z"
      transform="translate(-105 -70.89)"
      opacity="0.1"
    />
    <path
      d="M520.54,401.3s28,7.41,47.58,11a19.74,19.74,0,0,1,14.24,10.88c5.91,12.35,14.92,34.66,13.37,53.28l17.09,67.22,49,59.24-44.43,20.51-50.13-55.83,41,106L531.93,677s-22.79-170.9-17.09-199.38Z"
      transform="translate(-105 -70.89)"
      fill="#4d8af0"
    />
    <polygon
      points="360.53 297.16 376.24 374.27 350.6 387.37 336.56 317.91 360.53 297.16"
      fill="#e0e0e0"
    />
    <polygon
      points="403.58 379.97 415.54 330.41 430.92 359.46 422.95 383.39 403.58 379.97"
      fill="#e0e0e0"
    />
    <path
      d="M551.87,465.67s0,77.47,10.25,83.17"
      transform="translate(-105 -70.89)"
      opacity="0.1"
    />
    <path
      d="M404.9,485s-23.65,76.3-17,84.29"
      transform="translate(-105 -70.89)"
      opacity="0.1"
    />
    <path
      d="M400.34,589.85s45.58,29.8,51.84,30.28"
      transform="translate(-105 -70.89)"
      opacity="0.1"
    />
    <path
      d="M468.13,348.89s.18,6.67-.44,14.7a52.42,52.42,0,0,0,56.69,31.33c-.3-8.53,1.33-17.08,6.41-24.39C549,344.33,468.13,348.89,468.13,348.89Z"
      transform="translate(-105 -70.89)"
      opacity="0.1"
    />
    <circle cx="409.84" cy="267.75" r="52.41" fill="#fda57d" />
    <path
      d="M548.09,316.61c6.35.85,14.44-1.27,15.66-7.55,1.55-7.95-9.08-13.11-10.72-21-.62-3,.12-6.08-.24-9.09-.71-6.08-5.63-10.76-10.63-14.29a81.73,81.73,0,0,0-54.35-14.48c-2.38.23-4.89.61-6.73,2.13-2.09,1.72-2.9,4.51-4.33,6.82-4.92,7.94-16,8.88-23.87,13.83-9.27,5.79-13.85,17.16-14.27,28.08s2.7,21.63,5.78,32.11c2.62,8.92,5.37,18.07,11.12,25.37s15.3,12.4,24.32,10.2c-4.14-3-1.13-9.91,3.35-12.33s10-2.84,13.89-6.2,5-8.84,6.23-13.83c1.58-6.3,3.86-12.68,8.45-17.28C520.77,310,537,315.12,548.09,316.61Z"
      transform="translate(-105 -70.89)"
      opacity="0.1"
    />
    <path
      d="M547,315.47c6.35.85,14.44-1.27,15.66-7.55,1.55-7.95-9.08-13.11-10.72-21-.62-3,.12-6.08-.24-9.09C550.94,271.7,546,267,541,263.49A81.73,81.73,0,0,0,486.68,249c-2.38.23-4.89.61-6.73,2.13-2.09,1.72-2.9,4.51-4.33,6.82-4.92,7.94-16,8.88-23.87,13.83-9.27,5.79-13.85,17.16-14.27,28.08s2.7,21.63,5.78,32.11c2.62,8.92,5.37,18.07,11.12,25.37s15.3,12.4,24.32,10.2c-4.14-3-1.13-9.91,3.35-12.33s10-2.84,13.89-6.2,5-8.84,6.23-13.83c1.58-6.3,3.86-12.68,8.45-17.28C519.63,308.89,535.9,314,547,315.47Z"
      transform="translate(-105 -70.89)"
      fill="#72351c"
    />
    <path
      d="M505.78,345.71c-.77,5.42-2.48,14.8-5.7,20.72a166.66,166.66,0,0,0-10.88,24h0s21,16.25,31.65,27.35a24.68,24.68,0,0,0,26.13,6.4c5.56-2,10.43-5.93,11.72-13.24,2-11.55-.38-20.26-2.66-25.42A19.09,19.09,0,0,1,556,370c.79-1.75,1.71-3.11,2.74-3.46,0,0-20.51-4.56-21.65,1.14s-17.09,11.39-20.51,1.14l-.94-22.61a4.94,4.94,0,0,0-4.94-4.74h0A4.93,4.93,0,0,0,505.78,345.71Z"
      transform="translate(-105 -70.89)"
      opacity="0.1"
    />
    <path
      d="M505.78,346.85c-.77,5.42-2.48,14.8-5.7,20.72a166.66,166.66,0,0,0-10.88,24h0s21,16.25,31.65,27.35a24.68,24.68,0,0,0,26.13,6.4c5.56-2,10.43-5.93,11.72-13.24,2-11.55-.38-20.26-2.66-25.42a19.09,19.09,0,0,1-.07-15.56c.79-1.75,1.71-3.11,2.74-3.46,0,0-20.51-4.56-21.65,1.14S520,380.22,516.55,370l-.94-22.61a4.94,4.94,0,0,0-4.94-4.74h0A4.93,4.93,0,0,0,505.78,346.85Z"
      transform="translate(-105 -70.89)"
      fill="#72351c"
    />
    <path
      d="M913,671s-20.59-20.61-28.16-48l1.77,0-.85-2.38h.85L883.93,613c7.59-7.46,13.2-14.69,14.11-18.8,1.54-6.92,3.07-44.36,4-70.38l.82.2c2-3.42,2.42-7.73,2.28-11.64a45.13,45.13,0,0,0-1.68-13l-3.71-37.15A25.34,25.34,0,0,0,890,444.78L852.4,415.61a23.91,23.91,0,0,1,7.38-6.54c2.15-1.14,4.65-1.92,6.1-3.87,2.62-3.53.39-8.79,2.18-12.81,1.34-3,4.64-4.68,6.57-7.37,3.95-5.51,1.09-13.12-1.86-19.22l-17.14-35.43c-4.75-9.81-9.54-19.7-16.18-28.34-20.33-26.48-59.45-39.65-91.34-28.17-9.09,3.27-17.46,9.55-21.34,18.39-1.35,3.08-2.16,6.44-4.12,9.17-4.37,6.09-12.9,7.24-19,11.62-7.79,5.63-10.68,16.23-9.59,25.78s5.47,18.37,9.79,27c4.54,9,9.23,18.27,16.68,25.09,3.48,3.19,7.74,6.15,8.85,10.74.95,3.94-.73,8-.38,12.08a12.29,12.29,0,0,0,1.46,4.84c-16.83,2.11-28.77,16.09-34.12,36.89-.33,1.29-.46,2-.46,2L693.51,479c-.05.93,0,1.83,0,2.72a28.33,28.33,0,0,0,3,14v75.63l-72,45.58c-10.8,4.8-8.4,43.19,13.2,50.38S678.52,629,678.52,629c13.15-.88,35.27-18.42,47.16-32a21.67,21.67,0,0,0,6.27,6.53c-8.58,19.34-25.55,59.35-22.84,67.47,1.63,4.9,43.77,5.35,89.32,4.39a21.76,21.76,0,0,0,3.65,1.61c4.86,1.62,9.6.56,14-2.05C865.36,673.54,913,671,913,671ZM852.19,524.35c1-2.77,4.28-4.28,8.67-5v29.17ZM848,421.85H848l.14-.21Z"
      transform="translate(-105 -70.89)"
      fill="url(#ffb5f874-c212-487b-9e8d-f484eff3d531)"
    />
    <path
      d="M739.86,487.89s-2.28,80.89-4.56,91.15S700,620,682.89,621.19c0,0-18.23,43.29-38.74,36.46s-22.79-43.29-12.53-47.85L700,566.5V479.91Z"
      transform="translate(-105 -70.89)"
      fill="#fdb797"
    />
    <path
      d="M738.15,587s-29.62,63.8-26.2,74.06,193.68,0,193.68,0S870.31,625.74,877.15,587Z"
      transform="translate(-105 -70.89)"
      fill="#3ad29f"
    />
    <path
      d="M760.94,397.88s4.56,44.43-3.42,54.69,31.9,16,35.32,16,39.88-12.53,46.71-16,12.53-28.48,12.53-28.48-34.18,6.84-44.43-33Z"
      transform="translate(-105 -70.89)"
      fill="#fdb797"
    />
    <path
      d="M749,283.9c-8.63,3.11-16.59,9.07-20.27,17.47-1.28,2.92-2.05,6.11-3.91,8.71-4.15,5.78-12.25,6.87-18,11-7.4,5.35-10.14,15.41-9.11,24.49s5.19,17.45,9.29,25.61c4.31,8.57,8.77,17.35,15.84,23.83,3.3,3,7.35,5.84,8.4,10.2.9,3.74-.69,7.65-.36,11.48.82,9.47,12.14,13.88,21.59,14.93,4.11.46,8.81.48,11.71-2.47,1.48-1.52,2.23-3.58,3.16-5.48a26.27,26.27,0,0,1,46.86-.48c2.22,4.26,3.51,9.44,7.54,12,6.17,4,14.43-.69,19-6.47s7.85-13,14.34-16.47c2-1.09,4.42-1.82,5.8-3.68,2.49-3.35.37-8.35,2.07-12.16,1.28-2.87,4.41-4.44,6.24-7,3.75-5.23,1-12.46-1.77-18.25l-16.28-33.65c-4.51-9.32-9.06-18.71-15.36-26.92C816.43,285.51,779.28,273,749,283.9Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M790.56,460.54s44.43,0,55.83-41l37.4,29a24.06,24.06,0,0,1,9.2,16.63l3.53,35.28s4,13.1-.57,21.08c0,0-47.28-12-48.42,1.71l33,92.28s-51.27,1.14-64.94-8c0,0-55.83,11.39-69.5-1.14s-27.34-11.39-16-39.88c0,0-22.79-22.79-12.53-54.69s.57.57.57.57S696,501.56,697.14,481l2.28-20.51s.12-.68.44-1.9c6.95-27,25.61-42,51.85-32.44,7.67,2.78,15.51-.41,15.51-.41S758.66,462.82,790.56,460.54Z"
      transform="translate(-105 -70.89)"
      opacity="0.1"
    />
    <path
      d="M790.56,458.26s44.43,0,55.83-41l37.4,29a24.06,24.06,0,0,1,9.2,16.63l3.53,35.28s4,13.1-.57,21.08c0,0-47.28-12-48.42,1.71l33,92.28s-51.27,1.14-64.94-8c0,0-55.83,11.39-69.5-1.14s-27.34-11.39-16-39.88c0,0-22.79-22.79-12.53-54.69s.57.57.57.57S696,499.28,697.14,478.77l2.28-20.51s.12-.68.44-1.9c6.95-27,25.61-42,51.85-32.44,7.67,2.78,15.51-.41,15.51-.41S758.66,460.54,790.56,458.26Z"
      transform="translate(-105 -70.89)"
      fill="#252a62"
    />
    <path
      d="M895.95,497s-2.28,80.89-4.56,91.15-35.32,41-52.41,42.15c0,0-18.23,43.29-38.74,36.46s-22.79-43.29-12.53-47.85l68.36-43.29V489Z"
      transform="translate(-105 -70.89)"
      fill="#fdb797"
    />
    <path
      d="M847,499.85s26.2,17.78,49,16.3c0,0,2.28-30.54-4.56-32.25S854.93,483.9,847,499.85Z"
      transform="translate(-105 -70.89)"
      opacity="0.1"
    />
    <path
      d="M847,498.71s26.2,17.78,49,16.3c0,0,2.28-30.54-4.56-32.25S854.93,482.76,847,498.71Z"
      transform="translate(-105 -70.89)"
      fill="#252a62"
    />
    <path
      d="M809.93,393.32l-46.71,6.84s1.05,10.28,1.26,22.09a52.43,52.43,0,0,0,55-8.94C815.54,408.5,812.17,402,809.93,393.32Z"
      transform="translate(-105 -70.89)"
      opacity="0.1"
    />
    <circle cx="677.59" cy="300.79" r="52.41" fill="#fdb797" />
    <path
      d="M796.4,361.6a16.37,16.37,0,0,1-7,1q-13.9-.09-27.79-1c-7-.47-14.5-1.3-19.91-5.79-7.28-6-8.43-17.37-4.67-26.05s11.43-15.08,19.53-20a58.28,58.28,0,0,1,14.72-6.64c15-4.08,31.64.93,43.57,10.93s19.54,24.44,23.57,39.48c1.39,5.2,3.18,11.4,8.33,13-3.43,5.7-11.8,6.61-17.8,3.75-8.86-4.21-13.4-20.33-21.35-22.87C798.73,344.56,803.89,358.16,796.4,361.6Z"
      transform="translate(-105 -70.89)"
      opacity="0.1"
    />
    <path
      d="M796.4,360.46a16.37,16.37,0,0,1-7,1q-13.9-.09-27.79-1c-7-.47-14.5-1.3-19.91-5.79-7.28-6-8.43-17.37-4.67-26.05s11.43-15.08,19.53-20A58.28,58.28,0,0,1,771.27,302c15-4.08,31.64.93,43.57,10.93s19.54,24.44,23.57,39.48c1.39,5.2,3.18,11.4,8.33,13-3.43,5.7-11.8,6.61-17.8,3.75-8.86-4.21-13.4-20.33-21.35-22.87C798.73,343.42,803.89,357,796.4,360.46Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
    />
    <path
      d="M729.61,563.65A201.83,201.83,0,0,1,782,556.81"
      transform="translate(-105 -70.89)"
      opacity="0.1"
    />
    <path
      d="M847,502.13s-17.09,12.53-12.53,19.37"
      transform="translate(-105 -70.89)"
      opacity="0.1"
    />
    <g opacity="0.7">
      <polygon
        points="859.31 555.42 299.9 555.42 299.9 604.34 332.46 604.34 332.46 613.88 332.46 758.22 827.91 758.22 827.91 613.88 827.91 604.34 859.31 604.34 859.31 555.42"
        fill="url(#8483b893-8b57-4eec-a040-6ce664ec9c86)"
      />
    </g>
    <rect x="337.5" y="583.91" width="485.35" height="174.32" fill="#e0e0e0" />
    <rect x="337.5" y="593.02" width="485.35" height="27.34" opacity="0.1" />
    <rect x="305.6" y="564.54" width="548.01" height="46.71" fill="#e0e0e0" />
    <rect
      x="384.14"
      y="553.04"
      width="104.82"
      height="14.81"
      rx="6.5"
      ry="6.5"
      fill="#535461"
    />
    <g opacity="0.7">
      <rect
        x="447.94"
        y="387.91"
        width="260.52"
        height="180.13"
        rx="28.22"
        ry="28.22"
        fill="url(#2944a9b5-2bb1-4246-b5f3-3c3767fdf1af)"
      />
    </g>
    <rect
      x="452.58"
      y="391.23"
      width="246.79"
      height="176.8"
      rx="26.84"
      ry="26.84"
      fill="#3e3f49"
    />
    <rect
      x="456"
      y="391.23"
      width="246.79"
      height="176.8"
      rx="26.84"
      ry="26.84"
      fill="#535461"
    />
    <circle cx="590.92" cy="478.42" r="7.98" fill="#e0e0e0" />
    <path
      d="M369.58,658.21s6.74,9.89,26.73,12.92"
      transform="translate(-105 -70.89)"
      opacity="0.1"
    />
    <path
      d="M548,150.89H477v-40h71Zm-68-3h65v-34H480Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M619,150.89H548v-40h71Zm-68-3h65v-34H551Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M690,150.89H619v-40h71Zm-68-3h65v-34H622Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M761,150.89H690v-40h71Zm-68-3h65v-34H693Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M690,110.89H619v-40h71Zm-68-3h65v-34H622Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M619,110.89H548v-40h71Zm-68-3h65v-34H551Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M285,150.89H214v-40h71Zm-68-3h65v-34H217Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M356,150.89H285v-40h71Zm-68-3h65v-34H288Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M356,110.89H285v-40h71Zm-68-3h65v-34H288Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M905,150.89H834v-40h71Zm-68-3h65v-34H837Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M976,150.89H905v-40h71Zm-68-3h65v-34H908Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
    <path
      d="M976,110.89H905v-40h71Zm-68-3h65v-34H908Z"
      transform="translate(-105 -70.89)"
      fill="#4285f4"
      opacity="0.2"
    />
  </svg>
)

export default Freelance
